import { api } from "@/instances/http";

class UsersService {
  findMany(params) {
    return api.post("/users/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  aggregate(params) {
    return api.post("/users/aggregate", { ...params }).then(response => {
      return response.data;
    });
  }
  count(params) {
    return api.post("/users/count", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params) {
    return api.post("/users/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params) {
    return api.post("/users/update", { ...params }).then(response => {
      return response.data;
    });
  }
  updateMany(params) {
    return api.post("/users/updateMany", { ...params }).then(response => {
      return response.data;
    });
  }
  additional(id, params = {}) {
    return api.post(`/users/${id}/additional`, { ...params }).then(response => {
      return response.data;
    });
  }
  changeBalance(id, params = {}) {
    return api
      .post(`/users/${id}/changeBalance`, { ...params })
      .then(response => {
        return response.data;
      });
  }
  getUserPayments(id) {
    return api.post(`/users/${id}/payments`, {}).then(response => {
      return response.data;
    });
  }
  getUserReferals(id) {
    return api.post(`/users/${id}/referals`, {}).then(response => {
      return response.data;
    });
  }
  getUserIps(id) {
    return api.post(`/users/${id}/ips`, {}).then(response => {
      return response.data;
    });
  }
  getUserUsedBonuses(id) {
    return api.post(`/users/${id}/usedBonuses`, {}).then(response => {
      return response.data;
    });
  }
  getUserHistory(id) {
    return api.post(`/users/${id}/history`, {}).then(response => {
      return response.data;
    });
  }

  getUserHistoryDetail(id, count = 1000) {
    return api
      .post(`/users/${id}/historyDetail`, { limit: count })
      .then(response => {
        return response.data;
      });
  }

  getUserCreatedBonuses(id) {
    return api.post(`/users/${id}/createdBonuses`, {}).then(response => {
      return response.data;
    });
  }

  getUserCreatedInvoices(id) {
    return api.post(`/users/${id}/createdInvoices`, {}).then(response => {
      return response.data;
    });
  }

  getUserGameLogs(id) {
    return api.post(`/users/${id}/gameLogs`, {}).then(response => {
      return response.data;
    });
  }
  // getUserHistoryDetail(id) {
  //   return api.post(`/users/${id}/historyDetail`, {}).then(response => {
  //     return response.data;
  //   });
  // }
  getUserChatBan(id) {
    return api.post(`/users/${id}/chatBan`, {}).then(response => {
      return response.data;
    });
  }
  createLogBanChat(data) {
    return api
      .post(`/users/${JSON.stringify(data)}/createLogBanChat`, {})
      .then(response => {
        return response.data;
      });
  }
  getProvidersCanPlay(id) {
    return api.post(`/users/${id}/canPlay`, {}).then(response => {
      return response.data;
    });
  }
  changeCanPlayProviders(id, params = {}) {
    return api
      .post(`/users/${id}/changeCanPlay`, { ...params })
      .then(response => {
        return response.data;
      });
  }
}

export default new UsersService();
