import InvoicesService from "@/services/invoices.service";

export default {
  namespaced: true,
  state: {
    invoices: [],
    loading: false,
    countRow: 0
  },
  getters: {
    invoices: state => state.invoices,
    loading: state => state.loading,
    countRow: state => state.countRow
  },
  mutations: {
    setInvoices(state, data) {
      state.invoices = data;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setCount(state, data) {
      state.countRow = data;
    }
  },
  actions: {
    findMany(store, params = {}) {
      store.commit("setLoading", true);
      InvoicesService.findMany(params).then(data => {
        store.commit("setInvoices", data);
        store.commit("setLoading", false);
      });
    },
    count(store, params = {}) {
      store.commit("setLoading", true);
      InvoicesService.count(params).then(data => {
        store.commit("setCount", data);
      });
    }
  }
};
