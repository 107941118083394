import { api } from "@/instances/http";

class InvoicesService {
  findMany(params) {
    return api.post("/invoices/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  count(params) {
    return api.post("/invoices/count", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params) {
    return api.post("/invoices/findUnique", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params) {
    return api.post("/invoices/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params) {
    return api.post("/invoices/update", { ...params }).then(response => {
      return response.data;
    });
  }
  create(params) {
    return api.post("/invoices/create", { ...params }).then(response => {
      return response.data;
    });
  }
  delete(params) {
    return api.post("/invoices/delete", { ...params }).then(response => {
      return response.data;
    });
  }
  createInvoice(params) {
    return api.post("/invoices/createInvoice", { ...params }).then(response => {
      return response.data;
    });
  }
}

export default new InvoicesService();
